import React from "react";

const TweetList = ({ onDefaultData, onSearchData }) => {
  console.log("default data:", onDefaultData, "searchData:", onSearchData);

  const dateConvert = (date) => {
    const a = new Date(date);
    return a.toLocaleString();
  };

  return (
    <div className="tweets_container">
      <div className="tweets tweets_primary">
        {onDefaultData &&
          onDefaultData.map((data) => (
            <div key={data.id_str} className="tweet">
              <a
                href={"https://twitter.com/" + data.user.screen_name}
                target="_blank"
                rel="noopener noreferrer"
                className="tweet_head"
              >
                <img
                  src={data.user.profile_image_url}
                  alt={data.user.profile_image_url}
                />

                <div>
                  <h4>{data.user.name}</h4>
                  <span>@{data.user.screen_name}</span>
                </div>
              </a>

              <div className="tweet_content">
                <p>{data.text}</p>
              </div>

              <a
                href={
                  "https://twitter.com/" +
                  data.user.screen_name +
                  "/status/" +
                  data.id_str
                }
                target="_blank"
                rel="noopener noreferrer"
                className="tweet_bottom"
              >
                <span>{dateConvert(data.created_at)}</span>

                <label>
                  <span>{data.retweet_count}</span>
                  Retweet
                </label>

                <label>
                  <span>{data.favorite_count}</span>
                  Beğeni
                </label>
              </a>
            </div>
          ))}
      </div>

      {onSearchData && (
        <div className="tweets primary">
          {onSearchData.map((data) => (
            <div key={data.id_str} className="tweet">
              <a
                href={"https://twitter.com/" + data.user.screen_name}
                target="_blank"
                rel="noopener noreferrer"
                className="tweet_head"
              >
                <img
                  src={data.user.profile_image_url}
                  alt={data.user.profile_image_url}
                />

                <div>
                  <h4>{data.user.name}</h4>
                  <span>@{data.user.screen_name}</span>
                </div>
              </a>

              <div className="tweet_content">
                <p>{data.text}</p>
              </div>

              <a
                href={
                  "https://twitter.com/" +
                  data.user.screen_name +
                  "/status/" +
                  data.id_str
                }
                target="_blank"
                rel="noopener noreferrer"
                className="tweet_bottom"
              >
                <span>{dateConvert(data.created_at)}</span>

                <label>
                  <span>{data.retweet_count}</span>
                  Retweet
                </label>

                <label>
                  <span>{data.favorite_count}</span>
                  Beğeni
                </label>
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TweetList;
