import React, { useState, useEffect } from "react";
import axios from "axios";

import Logo from "./assets/photos/logo.png";

/* COMPONENTS */
import TweetList from "./components/TweetList";
/* COMPONENTS */

function App() {
  const [onDefaultData, setOnDefaultData] = useState();
  const [onSearchData, setOnSearchData] = useState();
  const [searchText, setSearchText] = useState();

  useEffect(() => {
    axios
      .get("https://mdisec-back-end.herokuapp.com/")
      .then((data) => {
        setOnDefaultData(data.data.statuses);
      })
      .catch({});
  }, []);

  const handleSearch = () => {
    const URL =
      "https://mdisec-back-end.herokuapp.com/?searchWord=" + searchText;
    axios
      .get(URL)
      .then((data) => {
        setOnSearchData(data.data.statuses);
      })
      .catch({});
  };

  return (
    <div className="App">
      <img src={Logo} alt="MdiSEC" />

      <div className="search_form">
        <input
          onChange={(e) => setSearchText(e.target.value)}
          type="search"
          placeholder="Entry keyword"
        />
        <button onClick={() => handleSearch()}>Search!</button>
      </div>

      <TweetList onSearchData={onSearchData} onDefaultData={onDefaultData} />
    </div>
  );
}

export default App;
